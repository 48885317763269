import Content from "./Content"


function App() {
  return (
    <div className="App">
      <h1 className="main_heading">welcome to Q & A App </h1>
      <Content />
    </div>
  );
}

export default App;
